// General body templates used for parsing datatable column data

import moment from "moment";

/**
 * Date format template
 */
export const dateBodyTemplate = (date, noTime) => {
    const momentDate = moment(date);

    if (momentDate.isValid()) {
        return (
            <div className="column-date-wrap">
                <div>{momentDate.format('DD.MM.YYYY')}</div>
                {!noTime && <div>{momentDate.format('HH:mm:ss')}</div>}
            </div>
        );
    }
};

/**
 * Percent format template
 */
export const percentBodyTemplate = (value?: number): JSX.Element => {
    return (
        <span>{ value ?? 0 } %</span>
    );
};

/**
 * Pound format template
 */
export const poundBodyTemplate = (value: number): JSX.Element => {
    return (
        <span>£{value.toFixed(2)}</span>
    );
};
